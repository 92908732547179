import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#4E738A',
      primaryDark: '#992237',
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FDFBF8',
      neutralLight3: '#F5EDE2',
      neutralLight4: '#D4CDC3',
      neutralLight5: '#EBDCC8',
      neutralDark1: '#000000',
      neutralDark2: '#1E1E1C',
      neutralDark3: '#6E6E69',
      neutralDark4: '#272725',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Inter', sans-serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const chinese = {
  ...theme,
  fontFamily: {
    heading: "'Noto Sans TC', sans-serif",
    paragraph: "'Noto Sans TC', sans-serif",
  },
}

export const korean = {
  ...theme,
  fontFamily: {
    heading: "'Noto Sans KR', sans-serif",
    paragraph: "'Noto Sans KR', sans-serif",
  },
}

export const japanese = {
  ...theme,
  fontFamily: {
    heading: "'Noto Sans JP', sans-serif",
    paragraph: "'Noto Sans JP', sans-serif",
  },
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  pageContext,
}: Props) {
  let t

  switch (pageContext.languagePrefix) {
    case 'zh':
      t = chinese
      break
    case 'ko':
      t = korean
      break
    case 'ja':
      t = japanese
      break

    default:
      t = theme
  }

  return (
    <EmotionThemeProvider theme={t}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
